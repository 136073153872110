/*
=========================================================
* BanWaves - By LouLou
=========================================================

*/

import React, {useContext, useState} from "react";

// Chakra imports
import {
    AspectRatio,
    Box,
    Button,
    Checkbox,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    SimpleGrid,
    Switch,
    Tag,
    TagLabel,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import {MdOutlineManageSearch} from "react-icons/md";
import {TbCrown} from "react-icons/tb";

// Custom components
import {useLocale} from "../../../../utils/Language";
import {usePageInfo} from "../../../../contexts/PageInfoContext";
import {FiPlus, FiSettings} from 'react-icons/fi'
import SwitchField from "../../../../components/fields/impl/SwitchField";
import {createScan, getLastScans} from "../../../../api/internal";
import Card from "../../../../components/card/Card";
import SliderInput from "components/forms/SliderInput";
import {useAuth0} from "@auth0/auth0-react";
import {GuildContext} from "../../../../contexts/guild/GuildContext";
import {useQuery} from "react-query";
import toast from "react-hot-toast";
import {PremiumModal} from "../../../../components/modal/PremiumModal";
import {SettingsContext} from "../../../../contexts/SettingsContext";

export default function ScanCreationPanel() {
    const locale = useLocale()

    usePageInfo(locale({zh: "Start a new scan", en: "Start a new scan"}))


    // Chakra Color Mode
    const {id: serverId, premium} = useContext(GuildContext);
    const auth0 = useAuth0();
    const {devMode} = useContext(SettingsContext)

    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

    const [data, setData] = useState({
        matchingUValue: 3,
        matchingPPValue: 3,
        scanType: null,

        options: {
            defaultAvatar: true,
            ignoreBots: true,
            ignoreMods: true
        }
    })

    const scanHistoryQuery = useQuery(
        "server_scan_history",
        () => getLastScans(serverId, auth0, 1),
    );

    function returnData(){
        if(data.scanType === null)
            return;
        toast.promise(
            createScan(serverId, {
                scanType: data.scanType,
                threshold: data.scanType === 'matchingUsernames' ? data.matchingUValue : data.matchingPPValue
            }, auth0).then(r => {
                setTimeout(() => window.location.href = `/guild/${serverId}/scan`, 1500);
            }),
            {
                loading: 'Starting scan...',
                success: 'Scan successfully started!',
                error: 'Error while starting scan!',
            }
        );
    }

    // Chakra Color Mode
    const Switch = ({label, isChecked, onChange, ...props}) => {
        return <SwitchField
            reversed={true}
            fontSize="md"
            mb="20px"
            label={label}
            isChecked={isChecked}
            onChange={e => onChange(e.target.checked)}
            {...props}
        />
    }

    const checkboxData = [
        {
            title: 'Include default avatar',
            description: 'Default avatar are by default excluded in profile picture scans.',
            value: 'defaultAvatar'
        },
        {
            title: 'Ignore bots',
            description: 'Ignores Bots when running scans.',
            value: 'ignoreBots'
        },
        {
            title: 'Ignores moderators',
            description: 'Users with permission of KICK or BAN will be ignored in the scans.',
            value: 'ignoreMods'
        },
    ]

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            <SimpleGrid columns={{base: 1, lg: 2}} gap={5} mt={10}>
                {/* Main Fields */}
                <Card mb='20px' width="100%">
                    <Flex direction='column' >
                        <Flex mb='40px' justify='space-between' align='center'>
                            <Text fontSize='2xl' color={textColorPrimary} fontWeight='bold'>
                                Settings
                            </Text>
                            { devMode ? (<Button colorScheme="blue" size="sm" onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}>
                                <FiSettings />
                                Advanced settings
                            </Button>) : null }
                        </Flex>
                        {showAdvancedSettings && devMode ? (
                            <Box>
                                {checkboxData.map((checkbox, index) => (
                                    <Box key={index} border={'2px solid purple'} borderRadius='md' p={4} mb={4}>
                                        <Checkbox value={checkbox.value}>{checkbox.title}</Checkbox>
                                        <Text mt={2} color='gray.600'>{checkbox.description}</Text>
                                    </Box>
                                ))}
                            </Box>
                        ) : (
                            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '10px' }}>
                                <FormControl>
                                    <FormLabel>👉 Matching on profile username [OLD DISCORD USERNAMES]</FormLabel>
                                    <SimpleGrid columns={{ sm: 2, md: 2, xl: 2 }} spacing={{ base: '10px', xl: '0px' }}>
                                        <Switch size='lg' isChecked={data.scanType === 'matchingUsernames'}
                                                onChange={v => {
                                                    setData({...data, scanType: 'matchingUsernames' })
                                                }}/>
                                        <SliderInput onChange={v => {
                                            setData({...data, matchingUValue: v })
                                        }} value={data.scanType === 'matchingUsernames' ? data.matchingUValue : 0} textColorPrimary={textColorPrimary} disabled={data.scanType !== 'matchingUsernames'} />
                                    </SimpleGrid>
                                </FormControl>
                                <Divider orientation={'horizontal'} />
                                <FormControl>
                                    <FormLabel>👉 Matching on profile username [NEW DISCORD USERNAMES]</FormLabel>
                                    <SimpleGrid columns={{ sm: 2, md: 2, xl: 2 }} spacing={{ base: '10px', xl: '0px' }}>
                                        <Switch size='lg' isChecked={data.scanType === 'matchingUsernamesv2'}
                                                onChange={v => {
                                                    setData({...data, scanType: 'matchingUsernamesv2' })
                                                }}/>
                                        <SliderInput onChange={v => {
                                            setData({...data, matchingUValue: v })
                                        }} value={data.scanType === 'matchingUsernamesv2' ? data.matchingUValue : 0} textColorPrimary={textColorPrimary} disabled={data.scanType !== 'matchingUsernamesv2'} />
                                    </SimpleGrid>
                                </FormControl>
                                <Divider orientation={'horizontal'} />
                                <FormControl isInvalid={true}>
                                    <FormLabel>👉 Matching on profile pictures</FormLabel>
                                    <SimpleGrid columns={{ sm: 2, md: 2, xl: 2 }} spacing={{ base: '20px', xl: '0px' }}>
                                        <Switch size='lg' isChecked={data.scanType === 'matchingProfilePictures'}
                                                onChange={v => {
                                                    setData({...data, scanType: 'matchingProfilePictures' })
                                                }}/>
                                        <SliderInput onChange={v => {
                                            setData({...data, matchingPPValue: v })
                                        }} textColorPrimary={textColorPrimary} value={data.matchingPPValue} disabled={data.scanType !== 'matchingProfilePictures'} />
                                    </SimpleGrid>
                                </FormControl>
                                <Divider orientation='horizontal' />
                                <FormControl isInvalid={true}>
                                    <FormLabel>👉 Blacklist
                                        <Tag  style={{marginLeft: "10px"}} size='lg' colorScheme='yellow' borderRadius='full'>
                                            <TbCrown style={{marginRight: "5px"}}/>
                                            <TagLabel>Premium</TagLabel>
                                        </Tag>
                                    </FormLabel>
                                    <SimpleGrid columns={{ sm: 3, md: 3, xl: 3 }} spacing={{ base: '20px', xl: '10px' }}>
                                        <Switch
                                            mb='25px'
                                            me='30px'
                                            id='3'
                                            label='Usernames'
                                            desc='Get personalized offers and emails based on your orders & preferences.'
                                            isChecked={data.scanType === 'usernameBlacklist'}
                                            onChange={v => {
                                                setData({...data, scanType: 'usernameBlacklist' })
                                            }}
                                        />
                                        <Switch
                                            me='30px'
                                            id='4'
                                            textWidth='60%'
                                            label='Profiles pictures'
                                            desc='Checking this will allow us to notify you when we make updates to products you have downloaded/purchased.'
                                            isChecked={data.scanType === 'profilePictureBlacklist'}
                                            onChange={v => {
                                                setData({...data, scanType: 'profilePictureBlacklist' })
                                            }}
                                        />
                                        <Button colorScheme={'messenger'}><MdOutlineManageSearch  style={{marginRight: "5px"}}/>Manage my blacklist</Button>
                                    </SimpleGrid>
                                </FormControl>

                                <PremiumModal onClose={onClose} isOpen={isOpen}/>
                                <Button isDisabled={!data.scanType} isLoading={scanHistoryQuery.isLoading} colorScheme='whatsapp' onClick={() => (scanHistoryQuery.data?.length > 0 && premium.premiumType === 0) ? onOpen() : returnData()} variant={'solid'} mt={5} ml={"0px"}>
                                    <FiPlus style={{marginRight: "10px"}}></FiPlus>{premium.premiumType > 0 ? 'Start a new scan' : scanHistoryQuery.data?.length > 0 ? 'Start a Free scan (0 Left)' : 'Start a Free scan (1 Left)'}
                                </Button>
                            </SimpleGrid>
                        )}
                    </Flex>
                </Card>
                <AspectRatio w='100%' maxW='100%' ratio={1130 / 636}>
                    <iframe
                        style={{ borderRadius: '30px' }}
                        src='https://www.youtube.com/embed/lr90VQXhZHs'
                        title='YouTube video player'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allow-full-screen
                    />
                </AspectRatio>
            </SimpleGrid>
        </Box>
    );
}
